import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Dashboard = () => {
    const [processing, setProcessing] = useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [forms, setForms] = useState([]);
    const [streams, setStreams] = useState([]);
    const [years, setYears] = useState([]);
    // forms
    const formsStreamsYear = async () => {
        try {
            const res = await axios.get('/api/formsStreamsYear');
            setForms(res.data.forms);
            setStreams(res.data.streams);
            setYears(res.data.years);
        } catch (err) {
            setError('Failed to fetch Classes. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    //handle data submission    
    const navigate = useNavigate();
    const [classOverallAnalysisInput, setClassOverallAnalysis] = useState({
        class: '',
        stream: '',
        cycle: '',
        term: '',
        year: '',
        error_list: [],
    });
    const handleInput = (e) => {
        e.persist();
        setClassOverallAnalysis({ ...classOverallAnalysisInput, [e.target.name]: e.target.value });
    }
    const classOverallAnalysisSubmit = (e) => {
        e.preventDefault();
        setProcessing(true);
        const data = {
            class: classOverallAnalysisInput.class,
            stream: classOverallAnalysisInput.stream,
            cycle: classOverallAnalysisInput.cycle,
            term: classOverallAnalysisInput.term,
            year: classOverallAnalysisInput.year,
        }
        console.log('submitted data is:', data);
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/director-of-studies/classOverallAnalysis', data).then(res => {
                console.log('data received ' + res.data.status);
                if (res.data.status === 200) {
                    console.log('this is the data obtained', res.data)
                    navigate('/dos/overall-class-analysis', { state: res.data });
                } else {
                    setProcessing(false);
                    setClassOverallAnalysis({ ...classOverallAnalysisInput, error_list: res.data.validation_errors });
                }
            });
        });
    }
    useEffect(() => {
        formsStreamsYear();
    }, []);


    if (loading) {
        return (
            <div className="container-fluid px-4">
                <div className=" mb-4 mt-4">
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container-fluid px-4">
                <div className="card mb-4 mt-4">
                    <div className="card-body d-flex justify-content-center align-items-center flex-column" style={{ height: '300px' }}>
                        <h5 className="text-danger">{error}</h5>
                        <button className="btn btn-primary mt-3" onClick={() => formsStreamsYear()}>
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid px-4">
            <h4 className="mt-1">Dashboard</h4>
            <div className="row no-print mb-4">
                <div className="col-12 d-flex flex-wrap justify-content-center">
                    <button type="button" className="btn btn-sm btn-danger elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#enter_results">
                        <i className="fas fa-user"></i> Enter Results
                    </button>
                    <button type="button" className="btn btn-sm btn-warning elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#exam_sheet">
                        <i className="fa fa-fw fa-file-pdf-o"></i> Download Exam Sheet
                    </button>
                    <div className="btn-group m-1 mx-auto">
                        <button type="button" className="btn btn-sm btn-secondary elevation-2 m-1 dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <i className="fa fa-fw fa-bar-chart-o"></i> Average Analysis
                        </button>
                    </div>
                    <button type="button" className="btn btn-sm btn-success elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#exam_sheet">
                        <i className="fa fa-fw fa-file-pdf-o"></i> Series/Reports
                    </button>
                    <button type="button" className="btn btn-sm btn-secondary elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#exam_sheet">
                        <i className="fa fa-fw fa-file-pdf-o"></i> Grading
                    </button>
                    <button type="button" className="btn btn-sm btn-primary elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#exam_sheet">
                        <i className="fa fa-fw fa-file-pdf-o"></i> Full Paper
                    </button>
                    <button type="button" className="btn btn-sm btn-info elevation-2 m-1 mx-auto" data-toggle="modal" data-target="#exam_sheet">
                        <i className="fa fa-fw fa-file-pdf-o"></i> Term Dates
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-4 mx-auto">
                    <span style={{ color: '#000000', fontSize: '20px' }} onClick={handleShowModal}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <i className="fa fa-fw fa-line-chart text-warning" style={{ fontSize: '30px' }}></i>
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>OVERALL CLASS ANALYSIS</span>
                            </div>
                        </div>
                    </span>
                    <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5 className='text-sm m-0 p-0'>OVERALL CLASS ANALYSIS</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <form onSubmit={classOverallAnalysisSubmit}>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-4 col-6 mx-auto">
                                        <label htmlFor="name">Class:</label>
                                        <div className="input-group mb-3">
                                            <span className='text-danger pl-2'>{classOverallAnalysisInput.error_list.class}</span>
                                            <select className="form-control" name="class" onChange={handleInput} value={classOverallAnalysisInput.class}>
                                                <option value="" selected>Select Class</option>
                                                {forms.map((form, index) => (
                                                    <option value={form.name} key={index}>
                                                        {form.name === 18 ? 'Form 1' : form.name === 19 ? 'Form 2' : form.name === 20 ? 'Form 3' : form.name === 21 ? 'Form 4' : form.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 mx-auto">
                                        <label htmlFor="name">Stream:</label>
                                        <span className='text-danger pl-2'>{classOverallAnalysisInput.error_list.stream}</span>
                                        <div className="input-group mb-3">
                                            <select className="form-control" name="stream" onChange={handleInput} value={classOverallAnalysisInput.stream}>
                                                <option value="" selected>Select Stream</option>
                                                {streams.map((stream, index) => (
                                                    <option value={stream.name} key={index}>
                                                        {stream.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 mx-auto">
                                        <label htmlFor="name">Series:</label>
                                        <span className='text-danger pl-2'>{classOverallAnalysisInput.error_list.cycle}</span>
                                        <div className="input-group mb-3">
                                            <select className="form-control" name="cycle" onChange={handleInput} value={classOverallAnalysisInput.cycle}>
                                                <option value="" selected>Select Series</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 mx-auto">
                                        <label htmlFor="name">Term:</label>
                                        <span className='text-danger pl-2'>{classOverallAnalysisInput.error_list.term}</span>
                                        <div className="input-group mb-3">
                                            <select className="form-control" name="term" onChange={handleInput} value={classOverallAnalysisInput.term}>
                                                <option value="" selected>Select a Term</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6 mx-auto">
                                        <label htmlFor="name">Year:</label>
                                        <span className='text-danger pl-2'>{classOverallAnalysisInput.error_list.year}</span>
                                        <div className="input-group mb-3">
                                            <select className="form-control" name="year" onChange={handleInput} value={classOverallAnalysisInput.year} >
                                                <option value="" selected>Select Year</option>
                                                {years.map((year, index) => (
                                                    <option value={year.name} key={index}>
                                                        {year.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-between">
                                <Button variant="default" onClick={handleCloseModal}>Close</Button>
                                {processing ? (
                                    <Button className="btn btn-warning">Processing...</Button>
                                ) : (
                                    <Button variant="primary" type="submit">Submit</Button>
                                )}
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mx-auto">
                    <Link to="{!! route('directorOfStudyStudents') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-pie-chart text-info" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>OVERALL SUBJECT ANALYSIS</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mx-auto">
                    <Link to="{!! route('directorOfStudyExamAnalysis') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-area-chart text-secondary" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>SUBJECT ANALYSIS PER STUDENT</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesRevisionMaterials') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-bar-chart text-primary" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>MEAN GRADE SUMMARY</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesclassNameSubjects') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <i className="fa fa-fw  fa-male text-secondary" style={{ fontSize: '30px' }}></i>
                                <i className="fa fa-fw fa-female text-primary" style={{ fontSize: '30px' }}></i>
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>TOP BOYS/GIRLS</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesSubjects') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-star text-gold" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>TOP STUDENT PER SUBJECT</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesDepartments') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-building text-success" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>DEPARTMENTAL ANALYSIS</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-5 mx-auto">
                    <Link to="{!! route('directorOfStudiesDepartments') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1"><i className="fa fa-fw fa-graduation-cap text-success" style={{ fontSize: '30px' }}></i></span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>MOST IMPROVED/DROPPED STUDENTS</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-5 mx-auto">
                    <Link to="{!! route('directorOfStudiesDepartments') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <i className="fa fa-fw fa-arrow-circle-up text-success" style={{ fontSize: '30px' }}></i>
                                <i className="fa fa-fw fa-arrow-circle-down text-danger" style={{ fontSize: '30px' }}></i>
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '5px' }}>
                                <span className="info-box-text" style={{ fontSize: '14px', fontWeight: '600' }}>MOST IMPROVED/DROPPED SUBJECTS</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;