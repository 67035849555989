import React from 'react';
import Navbar from '../frontend/Navbar';
function Page403() {
    return (
        <>
            <Navbar />
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='card card-body mt-5'>
                            <h1>Page 403 | Forbidden</h1>
                            <h3>Access Denied. You are not an Admin</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Page403