import axios from 'axios';
import React, { useEffect, useState } from 'react';

const Teachers = () => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchStudents = async () => {
        try {
            const res = await axios.get('/api/students');
            setStudents(res.data.students);
        } catch (err) {
            setError('Failed to fetch students. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStudents();
    }, []);

    if (loading) {
        return (
            <div className="container-fluid px-4">
                <div className=" mb-4 mt-4">
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container-fluid px-4">
                <div className="card mb-4 mt-4">
                    <div className="card-body d-flex justify-content-center align-items-center flex-column" style={{ height: '300px' }}>
                        <h5 className="text-danger">{error}</h5>
                        <button className="btn btn-primary mt-3" onClick={() => fetchStudents()}>
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid px-4">
            <div className="card mb-4 mt-4">
                <div className="card-header">
                    <i className="fas fa-table me-1"></i>
                    Students
                </div>
                <div className="card-body">
                    <table id="datatablesSimple" className="table table-striped table-bordered" aria-label="Students Table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((student, index) => (
                                <tr key={index}>
                                    <td>{student.name}</td>
                                    <td>{student.email}</td>
                                    <td>{student.role}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Teachers;