import React, { useEffect, useState } from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import MasterLayout from "./layouts/security/MasterLayout";
import axios from "axios";
import swal from 'sweetalert';

const SecurityPrivateRoute = () => {
  const isLoggedIn = localStorage.getItem('auth_token');
  const [Authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/api/securityCheckingAuthicated')
      .then(res => {
        if (res.status === 200) {
          setAuthenticated(true);
          console.log('the user is logged in' + isLoggedIn);
        }
        setLoading(false);
      })
      .catch(err => {
        console.error('Error checking authorization:', err);
        setAuthenticated(false);
        setLoading(false);
      });

    axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
      if (err.response.status === 401) {
        swal('Unauthorized', err.response.data.message, 'warning');
        navigate('/securityLogin');
      }
      return Promise.reject(err);
    });
  }, [navigate, isLoggedIn]);

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status === 403) //Acccess Denied
    {
      swal('Forbidden', error.response.data.message, 'warning');
      navigate('/403');
    } else if (error.response.status === 404) //Page Not Found
    {
      swal('404 Error', 'Url/Page Not Found', 'warning');
      navigate('/404');
    }
    return Promise.reject(error);
  });

  if (loading) {
    return (
      <div className="container-fluid px-4">
        <div className=" mb-4 mt-4">
          <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={isLoggedIn && Authenticated ? <MasterLayout /> : <Navigate to="/securityLogin" replace />}
      />
    </Routes>
  );
};

export default SecurityPrivateRoute;