import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => (
    <footer class="py-1 bg-light mt-auto">
        <div class="container-fluid px-4">
            <div class="d-flex align-items-center justify-content-between small">
                <div class="text-muted">Copyright &copy; Your Website 2023</div>
                <div>
                    <Link to="#" style={{fontSize:'12px'}}>Privacy Policy</Link>
                    &middot;
                    <Link to="#" style={{fontSize:'12px'}}>Terms &amp; Conditions</Link>
                </div>
            </div>
        </div>
    </footer>
)
export default Footer;