import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

function Navbar() {
  const navigate = useNavigate();
  const logoutTimerRef = useRef(null);
  const [shouldLogout, setShouldLogout] = useState(false);

  const logoutSubmit = useCallback((e) => {
    e.preventDefault();
    if (!localStorage.getItem('auth_token')) {
      // User is already logged out, no need to make the logout request
      return;
    }
    axios.post('/api/logout')
      .then((res) => {
        if (res.data.status === 200) {
          localStorage.removeItem('auth_token');
          localStorage.removeItem('auth_name');
          navigate('/');
        }
      })
      .catch((error) => {
        console.error('Error during logout:', error);
        // Display an appropriate error message to the user
        alert('An error occurred during logout. Please try again.');
      });
  }, [navigate]);

  // Function to handle user inactivity
  const handleUserInactivity = useCallback(() => {
    logoutTimerRef.current = setTimeout(() => {
      setShouldLogout(true);
    }, 30000); // 30 seconds in milliseconds
  }, []);

  useEffect(() => {
    // Add event listeners to track user activity
    window.addEventListener('mousemove', handleUserInactivity);
    window.addEventListener('keydown', handleUserInactivity);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleUserInactivity);
      window.removeEventListener('keydown', handleUserInactivity);
      clearTimeout(logoutTimerRef.current);
    };
  }, [handleUserInactivity]);

  useEffect(() => {
    if (shouldLogout) {
      logoutSubmit(new Event('click'));
    }
  }, [shouldLogout, logoutSubmit]);

  const navigateToTeacherLogin = (e) => {
    e.preventDefault();
    navigate('/teacherLogin');
  };

  let AuthButtons = '';
  if (!localStorage.getItem('auth_token')) {
    AuthButtons = (
      <>
        <li className="nav-item">
          <span className="nav-link" onClick={navigateToTeacherLogin} aria-disabled="true">Teacher</span>
        </li>
      </>
    );
  } else {
    AuthButtons = (
      <>
        <li className="nav-item">
          <button type='button' onClick={logoutSubmit} className="nav-link btn btn-xs bg-danger text-xs text-white p-1 mt-1" aria-disabled="true">Logout</button>
        </li>
      </>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary shadow sticky-top">
      <div className="container">
        <Link className="navbar-brand" to="#">Navbar</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link active" aria-current="page" to="#">Home</Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </Link>
              <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="#">Action</Link></li>
                <li><Link className="dropdown-item" to="#">Another action</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li><Link className="dropdown-item" to="#">Something else here</Link></li>
              </ul>
            </li>
            {AuthButtons}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;