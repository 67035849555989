import React from "react";
import { Link } from 'react-router-dom'
const Sidebar = ({ onRemoveSidebar }) => {
    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <Link className="nav-link" to="./dashboard" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="nav-icon fas fa-tachometer-alt"></i></div>
                        Dashboard
                    </Link>
                    <Link className="nav-link" to="./exam-analysis" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon">
                            <i className="fas fa-chart-bar"></i>
                        </div>
                        Exam Analysis
                    </Link>
                    <Link className="nav-link" to="./teachers" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fa fa-fw fa-users"></i></div>
                        Teachers
                    </Link>
                    <Link className="nav-link" to="./students" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fa fa-fw fa-mortar-board"></i></div>
                        Students
                    </Link>
                    <Link className="nav-link" to="./classes" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"> <i className="fa fa-fw fa-institution"></i></div>
                        Classes
                    </Link>
                    <Link className="nav-link" to="./departments" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fa fa-fw fa-institution"></i></div>
                        Departments
                    </Link>
                    <Link className="nav-link" to="./" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fa fa-fw fa-clipboard"></i></div>
                        Subjects
                    </Link>
                    <Link className="nav-link" to="./" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fa fa-fw fa-clipboard"></i></div>
                        Revision Materials
                    </Link>
                </div>
            </div>
        </nav>
    );
}
export default Sidebar; 