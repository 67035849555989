import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ClassOverallAnalysis = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [students_overall_performance, setStudentsOverallPerformance] = useState(null);
    const [form, setForm] = useState('');
    const [stream, setStream] = useState(null);
    const [year, setYear] = useState(null);
    const [term, setTerm] = useState(null);
    const [mathSelect, setMathSelect] = useState(null);
    const [engSelect, setEngSelect] = useState(null);
    const [kisSelect, setKisSelect] = useState(null);
    const [bioSelect, setbioSelect] = useState(null);
    const [chemSelect, setChemSelect] = useState(null);
    const [phySelect, setPhySelect] = useState(null);
    const [geoSelect, setGeoSelect] = useState(null);
    const [histSelect, setHistSelect] = useState(null);
    const [creSelect, setCreSelect] = useState(null);
    const [bstSelect, setBstSelect] = useState(null);
    const [agrSelect, setAgrSelect] = useState(null);
    const [compSelect, setCompSelect] = useState(null);
    const [homsSelect, setHomsSelect] = useState(null);
    const [musicSelect, setMusicSelect] = useState(null);
    const [kslSelect, setKslSelect] = useState(null);
    const [frenchSelect, setFrenchSelect] = useState(null);
    const [schoolDetails, setSchoolDetails] = useState('');
    const [studentsNames, setStudentsNames] = useState({});
    const [studentsTotalSubjects, setStudentsTotalSubjects] = useState({});
    // const [isLoading, setIsLoading] = useState(true);
    // forms
    const formsStreamsYear = async () => {
        try {
            const res = await axios.get('/api/formsStreamsYear');
            setSchoolDetails(res.data.schoolDetail);
        } catch (err) {
            //
        } finally {
            //
        }
    };

    const fetchStudentNames = async (studentIds, seriesId) => {
        try {
            const res = await axios.post('/api/director-of-studies/studentInfo', { student_ids: studentIds , seriesId: seriesId});
            const names = res.data.names;
            const totalSubjects = res.data.totalSubjects;
            setStudentsNames(names);
            setStudentsTotalSubjects(totalSubjects);
            console.log('this is the names',studentsNames);
            console.log('this is the names',studentsTotalSubjects);
        } catch (err) {
            console.error(err);
        } finally {
            // setIsLoading(false);
        }
    };

    useEffect(() => {
        let studentData = location.state?.students_overall_performance;
        const studentIds = studentData.map(student => student.student_id);
        fetchStudentNames(studentIds);

        if (!studentData) {
            const storedData = localStorage.getItem('students_overall_performance');
            if (storedData) {
                studentData = JSON.parse(storedData);
            } else {
                navigate(-1);
                return;
            }
        }
        // Check if the data is available in the location state
        if (location.state && location.state.students_overall_performance) {
            setStudentsOverallPerformance(location.state.students_overall_performance);
            setForm(location.state.class);
            setStream(location.state.stream);
            setYear(location.state.year);
            setTerm(location.state.term);
            setMathSelect(location.state.mathSelect);
            setEngSelect(location.state.engSelect);
            setKisSelect(location.state.kisSelect);
            setbioSelect(location.state.bioSelect);
            setChemSelect(location.state.chemSelect);
            setPhySelect(location.state.phySelect);
            setGeoSelect(location.state.geoSelect);
            setHistSelect(location.state.histSelect);
            setCreSelect(location.state.creSelect);
            setBstSelect(location.state.bstSelect);
            setAgrSelect(location.state.agrSelect);
            setCompSelect(location.state.compSelect);
            setHomsSelect(location.state.homsSelect);
            setMusicSelect(location.state.musicSelect);
            setKslSelect(location.state.kslSelect);
            setFrenchSelect(location.state.frenchSelect);
        } else {
            // If the data is not available in the location state, check if it's in localStorage
            const storedData = localStorage.getItem('students_overall_performance');
            if (storedData) {
                setStudentsOverallPerformance(JSON.parse(storedData));
            } else {
                // If the data is not available in localStorage, navigate back to the previous page
                navigate(-1);
            }
        }

        // Save the data to localStorage when the component unmounts
        return () => {
            localStorage.setItem('students_overall_performance', JSON.stringify(students_overall_performance));
        };
    }, [location, navigate, students_overall_performance]);

    useEffect(() => {
        formsStreamsYear();
    }, []);

    if (!students_overall_performance) {
        return (
            <div className="container-fluid px-4">
                <div className=" mb-4 mt-4">
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container-fluid px-4">
            <div className="card mb-4 mt-1">
                <div className="card-body">
                    <div class="">
                        <div class="mb-0 p-0 mt-1 info_box_head headerBox rounded">
                            <div class="text-center pl-2 pr-2">
                                <span class="info-box-text p-0 m-0">
                                    <h6 style={{ textTransform: 'uppercase' }} className='p-0 m-0'>{schoolDetails.name}</h6>
                                </span>
                                <p class="p-0 m-0 headerParagraph">
                                    EXAMINATION DEPARTMENT
                                </p>
                                <p class="p-0 m-0 headerParagraph">
                                    OVERALL RESULTS
                                </p>
                                <span class="info-box-text mb-0">
                                    <p class="mb-0">
                                        <span class="float-left mb-0 table_header_left">
                                            FORM {form === '18' ? 1 + stream : form === '19' ? 2 + stream : form === '20' ? 3 + stream : form === '21' ? 4 + stream : form} TERM {term} {year}
                                        </span>
                                    </p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive p-2 pt-0 examTable">
                        <ul className="nav nav-pills ml-auto d-flex justify-content-between">
                            <li className="nav-item m-1">
                                <button type="submit" className="btn btn-sm bg-warning headerButton" target="blank"
                                    formtarget="blank"><i className="fas fa-download"></i>
                                    Score Confirmation
                                </button>
                            </li>
                            <li className="nav-item m-1">
                                <button type="submit" className="btn btn-sm btn-danger float-right headerButton" id="overall-results">
                                    <i className="fas fa-download"></i> Final Results
                                </button>
                            </li>
                        </ul>
                        <table id="datatablesSimple" className="table table-striped table-bordered" aria-label="Students Table">
                            <thead className='tableHeader mt-0 mb-0' style={{position: 'sticky',top: 0}}>
                                <tr>
                                    <th>ADM_NO</th>
                                    <th>NAME</th>
                                    <th>STRM</th>
                                    {engSelect && <th>ENG</th>}
                                    {kisSelect && <th>KIS</th>}
                                    {kslSelect && <th>KSL</th>}
                                    {mathSelect && <th>MAT</th>}
                                    {bioSelect && <th>BIO</th>}
                                    {chemSelect && <th>PHY</th>}
                                    {phySelect && <th>CHE</th>}
                                    {geoSelect && <th>HIS</th>}
                                    {histSelect && <th>GEO</th>}
                                    {creSelect && <th>CRE</th>}
                                    {bstSelect && <th>AGR</th>}
                                    {agrSelect && <th>BUS</th>}
                                    {compSelect && <th>COM</th>}
                                    {homsSelect && <th>MSC</th>}
                                    {musicSelect && <th>HSC</th>}
                                    {frenchSelect && <th>FRN</th>}
                                    <th>ENT</th>
                                    <th>KCPE</th>
                                    <th>TOTAL</th>
                                    <th>MG</th>
                                    <th>DEV</th>
                                    <th>S_PN</th>
                                    <th>C_PN</th>
                                </tr>
                            </thead>
                            <tbody>
                                {students_overall_performance.length > 0 ? (
                                    students_overall_performance.map((student, index) => (
                                        <tr key={index}>
                                            <td>{student.student_id}</td>
                                            <td>{studentsNames[student.student_id] || 'Unknown'}</td>
                                            <td>{student.student_id}</td>
                                            {engSelect && <td>ENG</td>}
                                            {kisSelect && <td>KIS</td>}
                                            {kslSelect && <td>KSL</td>}
                                            {mathSelect && <td>MAT</td>}
                                            {bioSelect && <td>BIO</td>}
                                            {chemSelect && <td>PHY</td>}
                                            {phySelect && <td>CHE</td>}
                                            {geoSelect && <td>HIS</td>}
                                            {histSelect && <td>GEO</td>}
                                            {creSelect && <td>CRE</td>}
                                            {bstSelect && <td>AGR</td>}
                                            {agrSelect && <td>BUS</td>}
                                            {compSelect && <td>COM</td>}
                                            {homsSelect && <td>MSC</td>}
                                            {musicSelect && <td>HSC</td>}
                                            {frenchSelect && <td>FRN</td>}
                                            <td>{studentsTotalSubjects[student.student_id] || 'Unknown'}</td>
                                            <td>{student.student_id}</td>
                                            <td>{student.student_id}</td>
                                            <td>{student.student_id}</td>
                                            <td>{student.student_id}</td>
                                            <td>{student.student_id}</td>
                                            <td>{student.student_id}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={19} className='text-center'>No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassOverallAnalysis;