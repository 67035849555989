import React from 'react';
import {Link} from 'react-router-dom';

const Profile = () => {
  return (
    <div className="container-fluid px-4">
        <h4 className="mt-4">Profile</h4>
        <ol className="breadcrumb mb-4">
            <li className="breadcrumb-item active">Profile</li>
        </ol>
        <div className="row">
            <div className="col-xl-12 col-md-12  bg-success rounded">
                <div className="car text-white mb-4">
                    <div className="card-body">Primary Card</div>
                    <div className="card-footer d-flex align-items-center justify-content-between">
                        <Link className="small text-white stretched-link" to="#">View Details</Link>
                        <div className="small text-white"><i className="fas fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
export default Profile;