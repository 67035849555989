import Dashboard from '../components/teacher/Dashboard';
import Profile from '../components/teacher/Profile';
import ExamAnalysis from '../components/teacher/ExamAnalysis';
import Teachers from '../components/teacher/Teachers';

const teacherRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        element: <Dashboard />
    },
    {
        path: '/profile',
        name: 'Profile',
        element: <Profile />
    },
    {
        path: '/exam-analysis',
        name: 'Profile',
        element: <ExamAnalysis />
    },
    {
        path: '/teachers',
        name: 'Teachers',
        element: <Teachers />
    },
];

export default teacherRoutes;