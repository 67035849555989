import React from "react";
import {Link} from 'react-router-dom'
const Sidebar = ({ onRemoveSidebar })=>{
    return (
        <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
            <div className="sb-sidenav-menu">
                <div className="nav">
                    <Link className="nav-link" to="./dashboard" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Dashboard
                    </Link>
                    <Link className="nav-link" to="./profile" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Profile
                    </Link>
                    <Link className="nav-link" to="./exam-analysis" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Exam Analysis
                    </Link>
                    <Link className="nav-link" to="./teachers" onClick={onRemoveSidebar}>
                        <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                        Teachers
                    </Link>
                </div>
            </div>
        </nav>
    );
}
export default Sidebar; 