import React, { useState } from 'react';
import Navbar from '../Navbar';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
function PrincipalLogin() {
    const [loginProcess, setLoginProcess] = useState(false);
    const navigate = useNavigate();
    const [loginInput, setLogin] = useState({
        username: '',
        password: '',
        error_list: [],
    });
    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }
    const loginSubmit = (e) => {
        e.preventDefault();
        setLoginProcess(true);
        const data = {
            username: loginInput.username,
            password: loginInput.password,
        }
        axios.get('/sanctum/csrf-cookie').then(response => {
            axios.post('api/principalLogin', data).then(res => {

                console.log('data received ' + res.data.test);
                if (res.data.status === 200) {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('auth_name', res.data.username);
                    // swal("Success",res.data.message,"success");
                    if (res.data.role === 'principal') {
                        console.log('data received ' + res.data.role);
                        navigate('/principal/dashboard');

                    } else {
                        navigate('/');
                    }
                } else if (res.data.status === 401) {
                    setLoginProcess(false);
                    swal("Warning", res.data.message, "warning");

                } else {

                    setLoginProcess(false);
                    setLogin({ ...loginInput, error_list: res.data.validation_errors });
                }
            });
        });
    }
    return (
        <div>
            <Navbar />
            <div className="container py-5">
                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <div className='card-header'>
                                <h4 className='text-center'>Principal</h4>
                            </div>
                            <div className='card-body'>
                                <form onSubmit={loginSubmit}>
                                    <div className='form-group mb-3'>
                                        <label>Username</label>
                                        <input type='text' name='username' onChange={handleInput} value={loginInput.username} className='form-control' />
                                        <span className='text-danger pl-2'>{loginInput.error_list.username}</span>
                                    </div>
                                    <div className='form-group mb-3'>
                                        <label>Password</label>
                                        <input type='password' name='password' onChange={handleInput} value={loginInput.password} className='form-control' />
                                        <span className='text-danger pl-2'>{loginInput.error_list.password}</span>
                                    </div><div className="form-group mb-3" style={{ display: "flex", justifyContent: "center" }}>
                                        {loginProcess ? (
                                            <span className="btn btn-warning">Processing...</span>
                                        ) : (
                                            <button type="submit" className="btn btn-primary">
                                                Login
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrincipalLogin