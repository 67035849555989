import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import teacherIcon from '../../../src/images/icons/teachers.png';
import studentIcon from '../../../src/images/icons/students.png';
import examIcon from '../../../src/images/icons/exams.png';
import revisionIcon from '../../../src/images/icons/past_papers.png';
import classesIcon from '../../../src/images/icons/classes.png';
import subjectIcon from '../../../src/images/icons/subjects.png';
import departmentIcon from '../../../src/images/icons/departments.png';
// import axios from 'axios';

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // const [forms, setForms] = useState([]);
    // const [streams, setStreams] = useState([]);
    // const [years, setYears] = useState([]);
    // forms
    const formsStreamsYear = async () => {
        try {
            // const res = await axios.get('/api/formsStreamsYear');
            // setForms(res.data.forms);
            // setStreams(res.data.streams);
            // setYears(res.data.years);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch Classes. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        formsStreamsYear();
    }, []);
    if (loading) {
        return (
            <div className="container-fluid px-4">
                <div className=" mb-4 mt-4">
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container-fluid px-4">
                <div className="card mb-4 mt-4">
                    <div className="card-body d-flex justify-content-center align-items-center flex-column" style={{ height: '300px' }}>
                        <h5 className="text-danger">{error}</h5>
                        <button className="btn btn-primary mt-3" onClick={() => formsStreamsYear()}>
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="container-fluid px-4">
            <h4 className="mt-4">Dashboard</h4>
            <div className="row no-print mb-4 ">
                <div className="col-12 ">
                    <button type="button" className="btn btn-danger elevation-2" data-toggle="modal"
                        data-target="#minimal">
                        <i className="fas fa-user"></i> Update Minimum Subjects Per Stream
                    </button>
                    <div className="modal fade" id="minimal">
                        <div className="modal-dialog modal-sm">
                            <form action="{!! route('directorOfStudiesMinimuSubjects') !!}" method="POST" enctype="multipart/form-data">
                                <input type="text" name="user" value="admin" hidden />
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-8 col-8 mx-auto">
                                                    <input type="number" name="minimum" value="{!! $minimuSubject !!}"
                                                        className="form-control" placeholder="enter number" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button type="button" className="btn btn-default"
                                            data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-primary">Update</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesTeachers') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={teacherIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Teachers</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudyStudents') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span class="info-box-icon elevation-1"><img
                                src={studentIcon} alt='teacher'
                                style={{ width: '30px', height: '30px' }} /></span>
                            <div className="info-box-content">
                                <span className="info-box-text">Students</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="../exam-analysis" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={examIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Exam Analysis</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesRevisionMaterials') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={revisionIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Revision Materials</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesclassNameSubjects') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={classesIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Classes</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesSubjects') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={subjectIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Subjects</span>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mx-auto">
                    <Link to="{!! route('directorOfStudiesDepartments') !!}" style={{ color: '#000000', fontSize: '20px' }}>
                        <div className="info-box d-flex align-items-center">
                            <span className="info-box-icon elevation-1">
                                <img src={departmentIcon} alt='teacher' style={{ width: '30px', height: '30px' }} />
                            </span>
                            <div className="info-box-content" style={{ marginLeft: '10px' }}>
                                <span className="info-box-text">Departments</span>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Dashboard;