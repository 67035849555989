import React, { useState } from "react";
import { Routes, Route, Navigate } from 'react-router-dom'
import '../../assets/admin/css/styles.css';
import '../../assets/admin/js/scripts';
import '../../bower_components/font-awesome/css/font-awesome.min.css';
import dosBackroundImage from '../../images/examAna.jpg';

import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

import routes from '../../routes/dosRouters'

const MasterLayout = () => {
  const [isSidebarToggled, setIsSidebarToggled] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarToggled(!isSidebarToggled);
  };
  const onRemoveSidebar = () => {
    setIsSidebarToggled('');
  };

  return (
    <div className={`sb-nav-fixed ${isSidebarToggled ? 'sb-sidenav-toggled' : ''}`}>
      <Navbar onToggleSidebar={toggleSidebar} onRemoveSidebar={onRemoveSidebar} />
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <Sidebar onRemoveSidebar={onRemoveSidebar} />
        </div>
        <div id="layoutSidenav_content" style={{
                background: `url(${dosBackroundImage}) no-repeat center center fixed`,
              }}>
          <main>
            <Routes>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              <Route
                path="/dos"
                element={<Navigate from="/dos" to="/dos/dashboard" replace />}
              />
            </Routes>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default MasterLayout;