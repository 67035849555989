import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/frontend/Home';
import TeacherLogin from './components/frontend/auth/teacherLogin';
import BursarLogin from './components/frontend/auth/bursarLogin';
import StudentLogin from './components/frontend/auth/studentLogin';
import PrincipalLogin from './components/frontend/auth/principalLogin';
import DosLogin from './components/frontend/auth/dosLogin';
import MatronLogin from './components/frontend/auth/matronLogin';
import LibrarianLogin from './components/frontend/auth/librarianLogin';
import NurseLogin from './components/frontend/auth/nurseLogin';
import SecurityLogin from './components/frontend/auth/securityLogin';
import OtherStaffLogin from './components/frontend/auth/otherStafLogin';
// import Register from './components/frontend/auth/Register';
import Page403 from './components/errors/Page403';
import Page404 from './components/errors/Page404';
import TeacherPrivateRoute from './TeacherPrivateRoute';
import PrincipalPrivateRoute from './PrincipalPrivateRoute';
import DosPrivateRoute from './DosPrivateRoute';
import StudentPrivateRoute from './StudentPrivateRoute';
import BursarPrivateRoute from './BursarPrivateRoute';
import MatronPrivateRoute from './MatronPrivateRoute';
import LibrarianPrivateRoute from './LibrarianPrivateRoute';
import NursePrivateRoute from './NursePrivateRoute';
import SecurityPrivateRoute from './SecurityPrivateRoute';
import OtherStaffPrivateRoute from './OtherStaffPrivateRoute';
import axios from 'axios';
axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/403" element={<Page403 />} />
                    <Route path="/404" element={<Page404 />} />
                    <Route path='/teacherLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/teacher/dashboard" /> : <TeacherLogin />} />
                    <Route path='/bursarLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/bursar/dashboard" /> : <BursarLogin />} />
                    <Route path='/studentLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/student/dashboard" /> : <StudentLogin />} />
                    <Route path='/principalLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/principal/dashboard" /> : <PrincipalLogin />} />
                    <Route path='/dosLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/dos/dashboard" /> : <DosLogin />} />
                    <Route path='/matronLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/matron/dashboard" /> : <MatronLogin />} />
                    <Route path='/librarianLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/librarian/dashboard" /> : <LibrarianLogin />} />
                    <Route path='/nurseLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/nurse/dashboard" /> : <NurseLogin />} />
                    <Route path='/securityLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/security/dashboard" /> : <SecurityLogin />} />
                    <Route path='/otherStaffLogin' element={localStorage.getItem('auth_token') ? <Navigate to="/otherStaff/dashboard" /> : <OtherStaffLogin />} />
                    <Route path='/teacher/*' element={<TeacherPrivateRoute />} />
                    <Route path='/principal/*' element={<PrincipalPrivateRoute />} />
                    <Route path='/dos/*' element={<DosPrivateRoute />} />
                    <Route path='/student/*' element={<StudentPrivateRoute />} />
                    <Route path='/bursar/*' element={<BursarPrivateRoute />} />
                    <Route path='/matron/*' element={<MatronPrivateRoute />} />
                    <Route path='/librarian/*' element={<LibrarianPrivateRoute />} />
                    <Route path='/nurse/*' element={<NursePrivateRoute />} />
                    <Route path='/security/*' element={<SecurityPrivateRoute />} />
                    <Route path='/otherStaff/*' element={<OtherStaffPrivateRoute />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
