import Dashboard from '../components/dos/Dashboard';
import ExamAnalysis from '../components/dos/exam/index';
import OverallClassAnalysis from '../components/dos/exam/classOverallAnalysis';
const dosRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        element: <Dashboard />
    },
    {
        path: '/overall-class-analysis',
        name: 'OverallClassAnalysis',
        element: <OverallClassAnalysis />
    },
    {
        path: '/exam-analysis',
        name: 'ExamAnalysis',
        element: <ExamAnalysis />
    },
];

export default dosRoutes;