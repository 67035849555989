import React from 'react';
import Navbar from './Navbar';
function Home(){
    return(
        <div>
            <Navbar />
            <h1>This is the Home page</h1>
        </div>
    )
}
export default Home